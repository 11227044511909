import MineDrilling from '@/pages/reports/driller_prod/MineDrilling'
import Report from '@/components/Report'
import { useFilledReport, useReports } from '@/hooks'
import { useTranslation } from 'react-i18next'
import Drawing, { useDrawing } from '@/components/forms/Drawing'
import { useState } from 'preact/hooks'
import { makeMatrixData } from './utils'
import Btn from '@/components/forms/Btn'
import classNames from 'classnames'
import { DateTime } from 'luxon'
import { isQueryLoading } from '@/helpers'
import Loading from '@/components/Loading'
import DrillHeader from '@/components/forms/DrillHeader'
import { getProjects } from '@/api/resources'
import { useQuery } from '@tanstack/react-query'
import Delays from './Delays'

const DrillerProdReport: FC<ReportProps> = p => {
	const { t } = useTranslation('driller')
	const [formOption, setFormOption] = useState<string | undefined>(undefined)
	const [formOptionConfirmed, setFormOptionConfirmed] = useState(false)
	const [reportId, setReportId] = useState<number | undefined>(undefined)
	const r = useFilledReport(reportId?.toString() ?? p.report_id)
	const [units, setUnits] = useState(r?.form?.units ?? 'meters')
	const [drillingType, setDrillingType] = useState(r?.form?.form_type ?? 'mine')
	const { status: reportsStatus, fetchStatus: reportsFetchStatus, data: reports } = useReports()
	const { status: projectsStatus, fetchStatus: projectsFetchStatus, data: projects } = useQuery({
		queryKey: ['projects'],
		queryFn: getProjects,
	})


	const defaultCollapsed = reportId != undefined && !p.disabled
	const { setBlob: setSupervisorSignature, append: appendSupervisorSignature } = useDrawing(
		'form[supervisor_signature]',
		'supervisor_signature.png'
	)

	const { setBlob: setDrillerSignature, append: appendDrillerSignature } = useDrawing(
		'form[driller_signature]',
		'driller_signature.png'
	)

	const { setBlob: setClientSignature, append: appendClientignature } = useDrawing(
		'form[client_signature]',
		'client_signature.png'
	)

	const removeNulls = (fd: FormData) => {
		if (!fd.get('equipments[0][equipment_id]')) {
			fd.delete('equipments[0][id]')
			fd.delete('equipments[0][start_time]')
			fd.delete('equipments[0][end_time]')
			fd.delete('equipments[0][duration]')
			fd.delete('equipments[0][diameter]')
		}

		if (!fd.get('equipments[1][equipment_id]')) {
			fd.delete('equipments[1][equipment_id]')
			fd.delete('equipments[1][start_time]')
			fd.delete('equipments[1][end_time]')
			fd.delete('equipments[1][duration]')
		}

		for (var pair of fd.entries()) {
			if ((!pair[1] || pair[1] == '') && !pair[0].includes('matrix')) {
				fd.delete(pair[0])
			}
		}
	}

	const submitArgs = {
		async process(fd: FormData) {
			removeNulls(fd)
			appendSupervisorSignature(fd)
			appendDrillerSignature(fd)
			appendClientignature(fd)
		}
	}

	const handleFormOptionConfirmation = (e: Event) => {
		setFormOptionConfirmed(true)
	}

	if (!p.disabled && (!formOption || !formOptionConfirmed) && !p.report_id) {
		return (
			<>
				<input type="radio" name="formOption" id="continueForm" onClick={(e) => setFormOption('continue_form')} />
				<label htmlFor="continueForm">{t('continue_existing_form')}</label>
				<br />
				<br />
				<input type="radio" name="formOption" id="newForm" onClick={(e) => setFormOption('new_form')} />
				<label htmlFor="newForm">{t('create_new_form')}</label>
				<br />
				<br />
				<Btn class={classNames('w-25')} onClick={handleFormOptionConfirmation}>
					{t('forms:next')}
				</Btn>
			</>
		)
	}

	if ((isQueryLoading(reportsStatus, reportsFetchStatus) || isQueryLoading(projectsStatus, projectsFetchStatus)) && formOption === 'continue_form' && !p.report_id) {
		return (
			<Loading />
		)
	}

	if (formOption === 'continue_form' && !reportId && ! p.report_id) {
		return (
			<ol class='grid grid-cols-2 gap-4'>
				{(reports && projects) && reports.filter(r => r.form_type === 'driller_prod')
					.filter(r => r?.form?.is_complete != '1')
					.map(r => (
						<li>
							<a
								onClick={(e) => setReportId(r.id)}
								class='block h-full w-full rounded-md bg-white px-4 py-4 shadow-sm'
							>
								<div class='mb-auto font-medium text-gray-800'>
									{t('forms:project') + ': ' + (projects?.find(t => t.id === r.project_id)?.name ?? '-')}
								</div>
								<div class='mt-2 text-sm text-gray-600'>
									{r.form?.drilling_block != undefined ? t('drilling_block') + ': ' + r.form.drilling_block : ''}
								</div>
								<div class='mt-2 text-right text-sm text-gray-600'>
									{DateTime.fromISO(r.date_in).toISODate()} 
								</div>
							</a>
						</li>
					))}
			</ol>
		)
	}

	return (
		<Report submitArgs={submitArgs} type='driller_prod' report={r} disabled={p.disabled === 'disabled'}>
			<input type='hidden' name='version' defaultValue='2' />
			<input type="hidden" name={`form[units]`} value={units} />
			<input type="hidden" name="_method" value={r?.id != undefined ? 'PUT' : 'POST'}/>
			<DrillHeader report={r} disabled={p.disabled === 'disabled'} setUnits={setUnits} setDrillingType={setDrillingType} units={units} drilllingType={drillingType}/>
			<MineDrilling value={makeMatrixData(r)} name='form' defaultCollapsed={defaultCollapsed} units={units} drillingType={drillingType}></MineDrilling>

			<Delays delays={r?.form?.delays} defaultCollapsed={defaultCollapsed}></Delays>
            <Drawing label={t('supervisor_signature')} setBlob={setSupervisorSignature}  /> 
            <Drawing label={t('driller_signature')} setBlob={setDrillerSignature} />
            <Drawing label={t('client_signature')} setBlob={setClientSignature} />
		</Report>
	)
}
export default DrillerProdReport
