import { useTranslation } from 'react-i18next'
import { MatrixData } from './Matrix'
import { useState } from 'preact/hooks'
import type { SummaryData } from './DrillingSummary'
import type { ChangeEvent, StateUpdater } from 'preact/compat'
import DrillingSummary from './DrillingSummary'
import type { HoleDescription } from './HoleDescriptionForm'
import HoleDescriptionForm from './HoleDescriptionForm'
import { getBorers } from '@/api/resources'
import { useQuery } from '@tanstack/react-query'
import { calculateSummary, initHolesArray } from './utils'
import Btn from '@/components/forms/Btn'
import CollapsibleFieldset from '@/components/CollapsibleFieldset'

const defaultHoleDescription: HoleDescription = {
    hole_number: '',
    is_hole_number_changed: false,
    depth_planned: 0,
    matrix_input: 0,
    drilled: 0,
    redrilled: 0,
    cleaned: 0,
    depth_measured: 0,
    water: '',
    angle: 0,
    clean: 0,
    remote: '',
    broken_steel: 0,
    broken_rocks: 0,
    trephine_number: '',
    time: 0,
    comment: '',
    hole_description: '',
    diameter_value: 0,
    diameter_units: '',
    time_casing: 0,
    casing_meters: 0
}

type Props = {
    name: string
    value: MatrixData | undefined
    units: string
    drillingType: string
    defaultCollapsed?: boolean
}

const MineDrilling: FC<Props> = ({ ...p }) =>  {
	const { t } = useTranslation('driller')
    const { data: borers } = useQuery<Api.Equipment[]>({
        queryKey: ['borers'],
        queryFn: getBorers
    })
    const [hasMatrix, setHasMatrix] = useState(false)
    const [matrixData, setMatrixData] = useState<MatrixData>(p.value ? p.value : {
        cols: 0,
        rows: 0,
        burden: 0,
        spacing: 0,
        holeDescriptions: initHolesArray(0, 0),
    })

    const handleInput = (holeIndex: number, field: string, value: number | string) => {
        const newHoleDescriptions = [...matrixData.holeDescriptions]
        const holeDescription = newHoleDescriptions.find((val, i) => {
            return i == holeIndex
        })
        if (holeDescription) {
            holeDescription[field] = value
            if (field == 'hole_number') {
                holeDescription.is_hole_number_changed = true
            }
        }
        
        setMatrixData({...matrixData, holeDescriptions: newHoleDescriptions})
    }

    const handleMatrixInput = (index: number, value: number, matrixValue: number) => {
        const newHoleDescriptions = matrixData.holeDescriptions

        newHoleDescriptions[index].depth_planned = value
        newHoleDescriptions[index].matrix_input = matrixValue

        setMatrixData({...matrixData, holeDescriptions: newHoleDescriptions})
    }

    const handleResize = (field: string, value: number): void => {
        let newHoleDescriptions = matrixData.holeDescriptions
        const cols = field == 'cols' ? value : matrixData.cols
        const rows = field == 'rows' ? value : matrixData.rows
        const length = cols * rows

        if (cols > 26 || rows > 26) {
            setMatrixData({...matrixData})
            return
        }

        if (newHoleDescriptions.length < length) {
            Array.from(Array(Math.abs(newHoleDescriptions.length - length))).forEach((val, i) => {
                newHoleDescriptions = [...newHoleDescriptions, structuredClone(defaultHoleDescription)]
            })
        } else if (newHoleDescriptions.length > length) {
            newHoleDescriptions = newHoleDescriptions.slice(0, length)
        }

        newHoleDescriptions.map((hole, i) => {
            if (!hole.is_hole_number_changed) {
                hole.hole_number = String.fromCharCode(Math.ceil((i + 1) / cols) + 64) + ((i % cols) + 1)
            }
        })

        setMatrixData({...matrixData, [field]: value, holeDescriptions: newHoleDescriptions})
    }

    const handleFormTypeSelect = (e: ChangeEvent) => {
        const type = (e.target as HTMLInputElement).value
        if (type == '') {
            return
        } else if (type == 'mine') {
            setMatrixData({
                cols: 0,
                rows: 0,
                burden: 0,
                spacing: 0,
                holeDescriptions: initHolesArray(0, 0),
            })
        } else {
            setMatrixData({
                cols: 2,
                rows: 2,
                burden: 0,
                spacing: 0,
                holeDescriptions: initHolesArray(2, 2),
            })
        }
    }

    const summary: SummaryData = calculateSummary(matrixData)

	return (
		<>
            <CollapsibleFieldset
                title={t('hole_description')}
                defaultCollapsed={p.defaultCollapsed}
            >
                <div class='flex flex-col gap-y-8'>
                    {matrixData.holeDescriptions?.map((val, i) =>
                        <CollapsibleFieldset
                            title={t('hole') + ' ' + (i + 1) + ' - ' + val.hole_number}
                            defaultCollapsed={p.defaultCollapsed}
                            className={'m-0'}
                        >
                            <HoleDescriptionForm
                                index={i}
                                hole={val}
                                handleInput={handleInput}
                                borers={borers}
                                units={p.units}
                            />
                        </CollapsibleFieldset>
                    )}
                    <div class='flex justify-end gap-x-2'>
                        {matrixData.holeDescriptions?.length && p.drillingType == 'mine' ? (
                            <Btn danger onClick={() => setMatrixData({...matrixData, holeDescriptions: matrixData.holeDescriptions.slice(0, -1)})}>
                                {t('forms:remove')}
                            </Btn>
                        ) : ''}
                        {
                            p.drillingType == 'mine' ? (
                                <Btn onClick={() => setMatrixData({...matrixData, holeDescriptions: [...matrixData.holeDescriptions, JSON.parse(JSON.stringify(defaultHoleDescription))]})}>{t('forms:add')}</Btn>
                            ) : ''
                        }
                    </div>
                </div>
            </CollapsibleFieldset>
                
            <DrillingSummary
				summary={summary}
                units={p.units}
			/>
                
		</>
	)
}

export default MineDrilling