import { AddressSelect, ProjectWithTaskSelect, TaskSelect } from '@/components/forms/FuzzySelect'
import CollapsibleFieldset from '@/components/CollapsibleFieldset'
import Input from '@/components/forms/Input'
import { INPUT_DATETIME_FMT } from '@/constants'
import { DateTime } from 'luxon'
import { StateUpdater, useContext, useEffect, useState } from 'preact/hooks'
import { useTranslation } from 'react-i18next'
import DrillEquipmentSelect from '@/pages/reports/driller_prod/DrillEquipmentSelect'
import { makeDrillEquipment } from '@/pages/reports/driller_prod/utils'
import Radio from './Radio'
import { getProjectsWithTask } from '@/api/resources'
import { groupBy } from 'lodash-es'
import globalContext from '@/context'
import { useQuery } from '@tanstack/react-query'

export type DrillHeaderForm = {
	project: Api.Project
	quarter: string
	timeEntries: TaskEntry[]
}

type TaskEntry = {
	id: number
	task_id: number
	myoctans_task_id: number
	BT: string
	traveling_time: number
	work_order_time: number
}

type Props = {
	report?: Api.Report
	type?: ReportType
	disabled?: boolean
	units: string
	drilllingType: string
	setUnits: StateUpdater<string>
	setDrillingType: StateUpdater<string>
}

const DrillHeader: FC<Props> = ({ report: r, ...p }) => {
	const { t, i18n } = useTranslation('forms')
	const [ state, actions ] = useContext(globalContext)
	const [ headerForm, setHeaderForm ] = useState<DrillHeaderForm>()
	const { data: projects } = useQuery({
		queryKey: ['projects-tasks'],
		queryFn: getProjectsWithTask,
	})

	useEffect(() => {
		if (r && projects != undefined) {
			const project = projects?.find(p => p.id == r.project_id)
			if (!project) {
				return
			}
			const timeEntries = project.tasks? project.tasks.map(task => {
				const timeEntry = r.form.time_entries?.find((entry: any) => {
					return entry.task_id == task.id
				})
				return {
					task_id: task.id,
					myoctans_task_id: task.myoctans_task_id,
					BT: task.BT,
					traveling_time: timeEntry?.traveling_time ?? 0,
					work_order_time: timeEntry?.work_order_time ?? 0,
				} as TaskEntry
			}): [] as TaskEntry[]
			setHeaderForm({...headerForm, project: project, timeEntries: timeEntries, quarter: r.form.quarter ?? t('driller:day')})
		}
	}, [r, projects])

	const handleProjectMatch = (project: object | undefined) => {
		const projectMatch = project as Api.Project
		const timeEntries = projectMatch?.tasks ? projectMatch.tasks.filter(task => !task.is_archived).map(task => {
			return {
				id: task.id,
				task_id: task.id,
				myoctans_task_id: task.myoctans_task_id,
				BT: task.BT,
				traveling_time: 0,
				work_order_time: 0,
			} as TaskEntry
		}) : []
		setHeaderForm({...headerForm, timeEntries: timeEntries, project: projectMatch})
	}

	const handleTimeChange = (e: Event, i: number, field: string) => {
		const target = e.target as HTMLInputElement
		const value = parseFloat(target.value)
		const timeEntries = headerForm?.timeEntries
		if (timeEntries) {
			const newTimeEntries = timeEntries.map((entry, j) => {
				if (i == j) {
					return {...entry, [field]: value}
				}
				return entry
			})
			setHeaderForm({...headerForm, timeEntries: newTimeEntries})
		}
	}

	const handleQuarterChange = (quarter: string) => {
		setHeaderForm({...headerForm, quarter: quarter})
	}

	const workQuarters: Record<any, string> = {
        day: t('driller:day'),
        evening: t('driller:evening'),
        night: t('driller:night'),
    }

	const now = DateTime.now()
	const executionTime = r?.date_in ? DateTime.fromISO(r.date_in) : now
	const defaultCollapsed = r != undefined && !p.disabled
	const indexedTimeEntries = headerForm?.timeEntries?.map((entry, index) => ({ ...entry, index }));

	return (
		<>
			<CollapsibleFieldset title={t('general_information')} defaultCollapsed={defaultCollapsed}>
				<Input
                    className='span6'
                    required
                    max={now.toFormat(INPUT_DATETIME_FMT)}
                    defaultValue={r?.date_in ? DateTime.fromISO(r?.date_in).toFormat(INPUT_DATETIME_FMT) : now.toFormat(INPUT_DATETIME_FMT)}
                    type='datetime-local'
                    name='date_in'
                    label={t('driller:date_in')}
                />
				<ProjectWithTaskSelect
					required
					name='project_id'
					selectedId={headerForm?.project ? headerForm?.project.id : undefined}
					label={t('project')}
					onMatch={handleProjectMatch}
				/>
				<AddressSelect
					readonly
					defaultValue={r?.form?.work_execution_address}
					selectedId={headerForm?.project?.supplier_work_execution_address_id}
					name='form[work_execution_address]'
					label={t('work_execution_address')}
					prefered='name'
				/>
				<Input
					name='form[drilling_block]'
					value={r?.form?.drilling_block}
					label={t('driller:drilling_block')}
				/>
				<Input
					name='form[ditch_number]'
					value={r?.form?.ditch_number}
					label={t('driller:ditch_number')}
				/>
				<fieldset>
                <legend>{t('quarter')}</legend>
					<Radio
						name='form[quarter]'
						checked={headerForm?.quarter ? parseQuarterOption(headerForm?.quarter, i18n.language) : t('driller:day')}
						choices={workQuarters}
						prefered='name'
						onChange={(e) => handleQuarterChange(e)}
					/>
					<CollapsibleFieldset title={t('driller:time_entries')}>
						{headerForm?.timeEntries ? Object.entries(groupBy(indexedTimeEntries, 'BT')).map(([category, tasks], index) => (
							
							<fieldset key={index}>
								<legend>{category}</legend>
								{tasks.map((task, i) => (
									<>
										<input type="hidden" name={`form[time_entries][${task.index}][task_id]`} value={task.task_id} />
										<TaskSelect
											required
											name={`form[time_entries][${task.index}][myoctans_task_id]`}
											selectedId={task.myoctans_task_id as number}
											label={t('task')}
											readOnly={true}
											className={'span2'}
										/>
										<Input
											label={t('hours')}
											className='span2'
											name={`form[time_entries][${task.index}][work_order_time]`}
											value={task.work_order_time}
											onBlur={(e) => handleTimeChange(e, task.index, 'work_order_time')}
											type="number"
											decimal
										/>
										<Input
											label={t('traveling')}
											className='span2'
											name={`form[time_entries][${task.index}][traveling_time]`}
											value={task.traveling_time}
											onBlur={(e) => handleTimeChange(e, task.index, 'traveling_time')}
											type="number"
											decimal
										/>
									</>
								))}
							</fieldset>
						)) :
							<p class="text-center">{t('driller:select_a_project')}</p>
						}
					</CollapsibleFieldset>
				</fieldset>
				<DrillEquipmentSelect defaultValue={makeDrillEquipment(r)}/>

				<label>{t('driller:drilling_type')}
                    <select name={`form[drill_type]`} onChange={(e) => p.setDrillingType(e.target?.value)}>
                        {/* <option value=''>{t('forms:select_an_option')}</option> */}
                        <option selected={p.drilllingType == 'mine'} value='mine'>{t('driller:mine')}</option>
                        {/* <option value='quarry'>{t('quarry')}</option> */}
                    </select>
                </label>
				<div>
					<label>{t('units')}</label>
					<select name={`form[units]`} onChange={(e: Event) => p.setUnits((e.target as HTMLInputElement).value )}>
						<option selected={p.units == 'meters'} value='meters'>{t('meters')}</option>
						<option selected={p.units == 'feet'} value='feet'>{t('feet')}</option>
					</select>
				</div>
			</CollapsibleFieldset>
			<hr />
		</>
	)
}

function parseQuarterOption(quarter: string, language: string) {
	if (language == 'en') {
		if (quarter == 'Jour') {
			return 'Day'
		} else if (quarter == 'Soir') {
			return 'Evening'
		} else if (quarter == 'Nuit') {
			return 'Night'
		} else {
			return quarter
		}
	} else {
		if (quarter == 'Day') {
			return 'Jour'
		} else if (quarter == 'Evening') {
			return 'Soir'
		} else if (quarter == 'Night') {
			return 'Nuit'
		} else {
			return quarter
		}
	}
}
export default DrillHeader
