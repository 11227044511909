import { useTranslation } from 'react-i18next'
import Input from '@/components/forms/Input'

export type SummaryData = {
	m: number
	m2: number
	m3: number
	drilled: number
	drilledRemote: number
    redrilled: number
    redrilledRemote: number
    cleaned: number
    cleanedRemote: number
    comment?: string
}

type Props = { 
    summary: SummaryData
    units: string
}

const DrillingSummary: FC<Props> = ({ summary, ...p }) => {
	const { t } = useTranslation('driller')

	return (
		<>
            <fieldset>
                <legend>{t('summary')}</legend>
                <Input
                    readonly
                    className='span2'
                    type='number'
                    decimal
                    name='form[summary][total_m]'
                    value={summary.m}
                    label={p.units == 'feet' ? t('total_f') : t('total_m')}
                />

                <Input
                    readonly
                    className='span2'
                    type='number'
                    decimal
                    name='form[summary][total_m2]'
                    value={summary.m2}
                    label={p.units == 'feet' ? t('total_f2') : t('total_m2')}
                />  

                <Input
                    readonly
                    className='span2'
                    type='number'
                    decimal
                    name='form[summary][total_m3]'
                    value={summary.m3}
                    label={p.units == 'feet' ? t('total_f3') : t('total_m3')}
                />  

                <Input
                    readonly
                    className='span2'
                    type='number'
                    decimal
                    name='form[summary][drilled]'
                    value={summary.drilled}
                    label={t('drilled')}
                />

                <Input
                    readonly
                    className='span2'
                    type='number'
                    decimal
                    name='form[summary][redrilled]'
                    value={summary.redrilled}
                    label={t('redrilled')}
                />

                <Input
                    readonly
                    className='span2'
                    type='number'
                    decimal
                    name='form[summary][cleaned]'
                    value={summary.cleaned}
                    label={t('cleaned')}
                />
                
                <Input
                    readonly
                    className='span2'
                    type='number'
                    decimal
                    name='form[summary][drilled_remote]'
                    value={summary.drilledRemote}
                    label={t('drilled_remote')}
                />

                <Input
                    readonly
                    className='span2'
                    type='number'
                    decimal
                    name='form[summary][redrilled_remote]'
                    value={summary.redrilledRemote}
                    label={t('redrilled_remote')}
                />

                <Input
                    readonly
                    className='span2'
                    type='number'
                    decimal
                    name='form[summary][cleaned_remote]'
                    value={summary.cleanedRemote}
                    label={t('cleaned_remote')}
                />  
                
                <Input
                    className='span6'
                    type='text'
                    value={summary.comment}
                    name='form[summary][comment]'
                    label={t('irregularity_comment')}
                />  
            </fieldset>
        
		</>
	)
}
export default DrillingSummary