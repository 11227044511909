import { useEffect, useState } from 'preact/hooks'
import { useTranslation } from 'react-i18next'
import { delaysChoices } from './utils'
import DynamicFieldset from '@/components/forms/DynamicFieldset'
import CollapsibleFieldset from '@/components/CollapsibleFieldset'
import Input from '@/components/forms/Input'

type Props = {
	delays: any
    defaultCollapsed: boolean
}

const Delays: FC<Props> = (p) => {
	const { t } = useTranslation('driller')
    const delayChoices = delaysChoices(t)
	const [ delays, setDelays ] = useState<any[]>([])

    useEffect(() => {
        if (p.delays) {
            setDelays(p.delays)
        }
    }, [p.delays])

	return (
		
        <DynamicFieldset
				data={delays}
				label={t('delays')}
				collapsible={true}
				defaultCollapsed={p.defaultCollapsed}
				make={(i, d) => (
					<CollapsibleFieldset
						title={t('delay') + ' #' + (i + 1) + (d?.name ? ' : ' + d.name : '')}
						defaultCollapsed={p.defaultCollapsed}>
						<label class="span4">
							{t('name')}
							<select name={`form[delays][${i}][name]`}>
								<option value=''>{t('forms:select_an_option')}</option>
								{
									Object.keys(delayChoices).map(o => (
										<option selected={delays?.at(i)?.name == delayChoices[o]} value={delayChoices[o]} >
											{delayChoices[o]}
										</option>
									))
								}
							</select>
						</label>
						<Input
							className='span2'
							type='number'
							defaultValue={delays?.at(i)?.duration ?? 0}
							decimal
							name={`form[delays][${i}][duration]`}
							label={t('duration')}
						/>
						<Input
							className='span6'
							type='text'
							defaultValue={delays?.at(i)?.reason ?? ''}
							name={`form[delays][${i}][reason]`}
							label={t('reason')}
						/>
					</CollapsibleFieldset>
				)}
			/>
		
	)

}
export default Delays
